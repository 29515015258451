/*
 * @Author: 韩明珠
 * @Date: 2023-02-02 16:53:56
 * @LastEditors: lyyyc0528 74447219+lyyyc0528@users.noreply.github.com
 * @LastEditTime: 2023-03-10 09:39:32
 * @Description: 
 */

import axios from "axios";
import env from './env'

const service = axios.create({
    baseURL: env.pro.baseURL,
    headers:{
        'Content-Type':'application/json;charset=UTF-8'
    },
    settimeout:50000
})

service.interceptors.request.use(
    config => {
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

service.interceptors.response.use(
    response => {
        if(response.status && response.status === 200){
            if(response.data.code === 0){
                return response.data
            }
            return Promise.resolve(response.data)
        }
    },
    error => {
        return Promise.reject(error)
    }
)

export default service