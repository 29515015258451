/*
 * @Author: 韩明珠
 * @Date: 2023-02-02 16:53:29
 * @LastEditors: 韩明珠
 * @LastEditTime: 2023-02-17 11:08:35
 * @Description: 
 */


export default{
    dev:{
        baseURL:'https://testzs.sxydcyy.com',
        version:'v1.0.1'
    },
    pro:{
        baseURL:'https://zs.sxydcyy.com',
        version:'v1.0.1'
    }
}