/*
 * @Author: lyyyc0528 74447219+lyyyc0528@users.noreply.github.com
 * @Date: 2022-11-18 14:25:06
 * @LastEditors: lyyyc0528 74447219+lyyyc0528@users.noreply.github.com
 * @LastEditTime: 2023-02-06 14:23:22
 * @FilePath: \bigscreen\src\main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import http from "@/http/request";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import echarts from 'echarts' //引入echarts
import 'lib-flexible' //大屏适应
import dataV from '@jiaminghi/data-view' //引入data-view

import { parseTime,formatMoney} from "@/utils/dataFormat";

// 适配flex
import '@/common/flexible.js';
//字体样式
import '../src/assets/css/font.css';

Vue.prototype.$echarts = echarts //挂载在原型，组件内使用直接this.$echarts调用npm install -D amfe-flexible
Vue.config.productionTip = false
Vue.prototype.$http = http;
Vue.prototype.parseTime = parseTime//时间戳转时间
Vue.prototype.formatMoney = formatMoney//金额逗号隔开
Vue.use(dataV)
Vue.use(ElementUI);

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
