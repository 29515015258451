/*
 * @Author: lyyyc0528 74447219+lyyyc0528@users.noreply.github.com
 * @Date: 2023-02-06 13:05:47
 * @LastEditors: lyyyc0528 74447219+lyyyc0528@users.noreply.github.com
 * @LastEditTime: 2023-02-07 09:13:41
 * @FilePath: \DataView\src\utils\formData.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// 日期格式化
export function parseTime(time, pattern) {
    if (arguments.length === 0 || !time) {
      return null
    }
    const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
    let date
    if (typeof time === 'object') {
      date = time
    } else {
      if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
        time = parseInt(time)
      } else if (typeof time === 'string') {
        time = time.replace(new RegExp(/-/gm), '/').replace('T', ' ').replace(new RegExp(/\.[\d]{3}/gm),'');
      }
      if ((typeof time === 'number') && (time.toString().length === 10)) {
        time = time * 1000
      }
      date = new Date(time)
    }
    const formatObj = {
      y: date.getFullYear(),
      m: date.getMonth() + 1,
      d: date.getDate(),
      h: date.getHours(),
      i: date.getMinutes(),
      s: date.getSeconds(),
      a: date.getDay()
    }
    const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
      let value = formatObj[key]
      // Note: getDay() returns 0 on Sunday
      if (key === 'a') {
        return ['日', '一', '二', '三', '四', '五', '六'][value]
      }
      if (result.length > 0 && value < 10) {
        value = '0' + value
      }
      return value || 0
    })
    return time_str
  }


//金额用逗号隔开
  export function formatMoney(s,type) {
    var result = s;
    if (s < 0) {
      s = 0 - s;
    }
    if (/[^0-9\.]/.test(s)) {
      return '0.00';
    }
    if (s == null || s == 'null' || s == '') {
      return '0.00';
    }
    s = s.toString().replace(/^(\d*)$/, '$1.');
    s = (s + '00').replace(/(\d*\.\d\d)\d*/, '$1');
    s = s.replace('.', ',');
    var re = /(\d)(\d{3},)/;
    while (re.test(s)) {
      s = s.replace(re, '$1,$2');
    }
    s = s.replace(/,(\d\d)$/, '.$1');
    if (type == 0) {
      var a = s.split('.');
      if (a[1] == '00') {
        s = a[0];
      }
    }
    if (result < 0) {
      result = '-' + s;
    } else {
      result = s;
    }
    return result;

  }