/*
 * @Author: lyyyc0528 74447219+lyyyc0528@users.noreply.github.com
 * @Date: 2022-11-18 15:32:15
 * @LastEditors: 韩明珠
 * @LastEditTime: 2023-02-06 13:35:40
 * @FilePath: \bigscreen\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */


//配置路由相关信息
import Vue from 'vue'
import VueRouter from 'vue-router'


//通过Vue.use(插件)，安装插件
Vue.use(VueRouter)

// 引入组件
const routes = [
  // {
  //   path:'/',
  //   component:() => import('@/views/Login.vue')
  // },
  {
    path:'/',
    component: ()=>import('../views/index/IndexPage.vue')
  },

]

const router = new VueRouter({
  //配置路由和组件之间的应用关系
  mode:'history',
  routes
})

//全局前置守卫：初始化时执行、每次路由切换前执行
router.beforeEach((to,from,next)=>{
  if(to.path == '/view'){ //判断当前路由是否需要进行权限控制
      if(localStorage.getItem('code')){ //权限控制的具体规则
          next() //放行
      }else{
          alert('暂无权限查看')
          // next({name:'guanyu'})
      }
  }else{
      next() //放行
  }
})
//将router对象传入到vue实例
export default router
