<!--
 * @Author: lyyyc0528 74447219+lyyyc0528@users.noreply.github.com
 * @Date: 2022-11-18 14:25:06
 * @LastEditors: 韩明珠
 * @LastEditTime: 2023-02-03 16:21:44
 * @FilePath: \bigscreen\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
}
</script>

<style>
 @import './assets/css/public.css'; /*引入公共样式*/
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

</style>
